<template>
    <div class="inner-section">
      <card>
      </card>
      <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title"> {{ $t('teaGardenConfig.data_migrate') }}</h4>
      </template>
      <template v-slot:headerAction>
        <a class="btn btn-primary btn-sm" target="_blank" :href="teaGardenServiceBaseUrl + 'btb_license_data_import.xlsx'">
        {{ $t('globalTrans.demo_excel') }}
        </a>
      </template>
      <template v-slot:body>
        <b-row>
            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <b-overlay :show="loading">
                <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
                    <b-row>
                        <b-col sm="6">
                            <ValidationProvider name="Attachment file" vid="excel_file">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="excel_file"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('globalTrans.excelFile')}}<span class="text-danger">*</span>
                                </template>
                                <b-form-file
                                id="excel_data"
                                v-on:change="onFileChange"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-file>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row class="text-right">
                    <b-col>
                        <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.submit') }}</b-button>
                    </b-col>
                    </b-row>
                </b-form>
                </b-overlay>
            </ValidationObserver>
          </b-row>
      </template>
      <!-- table section end -->
    </body-card>
    </div>
  </template>
  <script>
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
    import { btbLicenseDataImport } from '../../api/routes'
    export default {
    name: 'Form',
    data () {
        return {
            teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
            valid: null,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            errors: [],
            excel_file: ''
        }
    },
    created () {
    },
    computed: {
        loading: function () {
        return this.$store.state.commonObj.loading
        }
    },
    watch: {
    },
    methods: {
        onFileChange (e) {
            this.excel_file = e.target.files[0]
        },
        async saveUpdate () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            var formData = new FormData()
            formData.append('excel_file', this.excel_file)

            result = await RestApi.postData(teaGardenServiceBaseUrl, btbLicenseDataImport, formData, config)
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.dispatch('CommonService/mutateCommonObj', { hasDropdownLoaded: false })
                this.$toast.success({
                title: 'Success',
                message: this.$t('globalTrans.excelImportMessage'),
                color: '#D6E09B'
                })
                window.location.reload()
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        }
    }
    }
  </script>
